import React from "react"
import {Container} from "react-bootstrap"
import SEO from "../components/seo"
import ValuationCTA from "../components/valuationCta";
import Layout from "../components/layout";
import {graphql} from "gatsby";

const WhyChooseUsPage = ({data}) => {

    return (
        <Layout pageInfo={{pageName: "index"}}>
            <SEO title="Why Choose Us" description="As soon as our car buying expert has inspected your car and agreed the final price he will pay you either by cash or bank transfer the very same day." />

            <Container className="slice">
                <h1 className="text-center font-weight-800 mb-5">Why choose us?</h1>

                <div className="rounded p-4 bg-gray-100 mb-3">
                    <h4 className="text-primary-dark font-weight-800 mb-3">Same day cash at no additional cost to you</h4>
                    <p className="mb-0">Many of our competitors will charge you if you want the money for your vehicle paid to you on the same day. We don’t. As soon as our car buying expert has inspected your car and agreed the final price he will pay you either by cash or bank transfer the very same day with NO additional costs to you.
                    </p>
                </div>
                <div className="rounded p-4 bg-info mb-3">
                    <h4 className="text-white font-weight-800 mb-3">We pay MORE for your car</h4>
                    <p className="mb-0 text-white">High volume and small overheads means we are able to pay more for your car.
                    </p>
                </div>
                <div className="rounded p-4 bg-gray-100 mb-3">
                    <h4 className="text-primary-dark font-weight-800 mb-3">We come to you</h4>
                    <p className="mb-0">To make the process of selling your car even easier for you, one of our agents will contact you to arrange a suitable time for our local car buying experts to inspect the car and they will come to you so you don’t even have to leave the comfort of your own home.
                    </p>
                </div>
                <div className="rounded p-4 bg-gray-100 mb-3">
                    <h4 className="text-primary-dark font-weight-800 mb-3">DVLA paperwork filled out and sent off same day</h4>
                    <p className="mb-0">The paperwork can be confusing for people with regards as what to do when selling your car. Don’t worry, our local car buying experts will take care of this.
                    </p>
                </div>
                <div className="rounded p-4 bg-gray-100 mb-3">
                    <h4 className="text-primary-dark font-weight-800 mb-3">We will send local car buying experts</h4>
                    <p className="mb-0">As well as having our own team at ICC we also work with other independent traders from across the UK to come and buy your car. This has given us a bigger pool of expert buyers who are able to offer great prices.
                    </p>
                </div>
            </Container>

            <ValuationCTA></ValuationCTA>

        </Layout>
    )
}


export const data = graphql`
    {
          file(relativePath: { eq: "sara-banner-2.png" }) {
              childImageSharp {
                fluid {
                        ...GatsbyImageSharpFluid
                  }
              }
        }
    }
`

export default WhyChooseUsPage
